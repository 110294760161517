// import * as session from 'browser-session-store';
import * as session from 'browser-store'; //local storage // per browser
import Cookies from 'universal-cookie';

class ClientSession {
  static authkey =  "auth";
  static loggedin = null;
  static cookies = new Cookies();

  static storeAuth = (value, func) => {
    session.put(ClientSession.authkey, value, (err) => func(err));
  };

  static getAuth = (reciverfunc) => {
    session.get(ClientSession.authkey,(err, value) => reciverfunc(err, value) );
  };

  static updateAuthData = (newValue,func) => {
    session.get(ClientSession.authkey,(err, value) => {
      value.data = newValue
      session.put(ClientSession.authkey, value, (err) => func(err));
    });
  };

  static updateAuthDataPhoto = (newValue,func) => {
    session.get(ClientSession.authkey,(err, value) => {
      value.data.profilePicture = newValue
      session.put(ClientSession.authkey, value, (err) => func(err));
    });
  };

  static removeAuth = (func) => {
    session.remove(ClientSession.authkey, (err) => {
      func(err);
    });
  };

  static isLoggedIn = (func) => {
    ClientSession.getAuth((err, value)=> {
      if(err){
        console.error(err);
        func(false);
      }else {
        if(value == null){
            ClientSession.removeAuth((err)=> {if(err){console.log(err)}});
            func(false);
        } else if ((new Date(value.createdDate)).getTime() + value.ttl >= (new Date().getTime()) ) {
          func(true);
        }else {
          //expire
          //ClientSession.removeAuth((err)=> {if(err){console.log(err)}});
          func(true);
        }
      }
    });
  };

  static getToken = () => {

    if(ClientSession.isLoggedIn()){

      ClientSession.getAuth((err, value)=> {
        if(err){
          console.error(err);
          return false;
        }else {
          return value.id;
        }
      })
    }

  };

  static getAccessToken = (callback) => {

    ClientSession.isLoggedIn(function (isLoggedIn) {
      if(isLoggedIn){
        ClientSession.getAuth((err, value)=> {
          if(err){
            console.error(err);
            callback(false, err);
          }else {
            callback(true, value)
          }
        })
      }else{
        callback(false, null);
      }
    });

  };
}

export default ClientSession;
